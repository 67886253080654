// Override the softboilerplate's defaults here

// Css variables
:root {
  --plyr-color-main: #084b33;
}

// colors
$clrWhite: #fff;
$clrBlack: #000;

// Main colors
$clrPrimary: #084b33 !default;
$clrSecondary: #f9f0e8 !default;
$clrTertiary: #f5f3f3 !default;

// Background
$clrBg: $clrSecondary;
$clrBgLight: $clrTertiary;

$clrGreyBorder: #e6eae5 !default;
$clrGreyBorderDark: #c1c9c0 !default;
$clrGrey: #ddd !default;
$clrGreyLight: #f7f7f7 !default;
$clrGreyMedium: #e6eae5;
$clrGreyDark: #707070 !default;
$clrError: #e52020;
$clrSuccess: #06b232;

$clrBodyFont: $clrPrimary;
$clrHeaderFont: $clrPrimary;
$clrLinkFont: $clrPrimary;

// sizes & measuring
$bodyMaxWidth: 1600px;
$componentSpacings: (small: 15px, medium: 25px, large: 50px, xlarge: 100px);
$componentSpacingsMobile : (small: 15px, medium: 15px, large: 25px, xlarge: 50px);

// spacings
$paragraphMarginBottom: 50px;

// fonts & font sizes
$bodyFontFamily: 'Source Sans Pro', sans-serif;
$headerFontFamily: 'Tangerine', cursive;
$fontSize12: 0.75rem; // a practical system these to convert the pixel values in the design to rem. this way, referencing to the values in the design is easier. And changing the font-size site-wide is easier ('the font looks so big. can we make it smaller?')
$fontSize13: 0.8125rem;
$fontSize14: 0.875rem;
$fontSize16: 1rem;
$fontSize18: 1.125rem;
$fontSize20: 1.25rem;
$fontSize22: 1.375rem;
$fontSize24: 1.5rem;
$fontSize28: 1.75rem;
$fontSize32: 2rem;
$fontSize36: 2.25rem;
$fontSize40: 2.5rem;
$fontSize46: 2.875rem;
$fontSize56: 3.5rem;
$fontSize100: 6.25rem;
//
$bodyFontSize: $fontSize18;
$h1FontSize: $fontSize40;
$h2FontSize: $fontSize32;
$tinyFontSize: $fontSize12;
$bodyLineHeight: 1.5;
$headerLineHeight: 1.5;

$fntWeightBold: 700;

// animation
$transition-duration: 0.4s;
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeOutQuad: cubic-bezier(0.5, 1, 0.89, 1);
$easeInOutQuad: cubic-bezier(0.45, 0, 0.55, 1);
$easeInQuad: cubic-bezier(0.11, 0, 0.5, 0);

// Transition duration
$durationFast: 100ms;
$durationNormal: 300ms;
$durationSlow: 500ms;

// z-indexes
$zBottom: 0;
$zMiddle: 1;
$zTop: 2;

$zMenuTop: 11;
$zMenuMiddle: 10;
$zMenuBottom: 9;

// Geysir
$dialogBtnBg: #504d61;
$clrTableDrag: #0a6fb4;
$clrBgParagraphWrapper: rgba(85, 142, 213, 0.1);
