body {
  // @include poppins;
  font-family: $bodyFontFamily;
  font-size: $bodyFontSize;
  line-height: $bodyLineHeight;
  -webkit-font-smoothing: auto;
  text-rendering: optimizeLegibility;
  color: $clrBodyFont;
  overflow-x: hidden;
}

.app-body { // all styles below an app-body class, so it won't impact the drupal menu
  h1,
  .h1-style {
    // @include poppinsBlackItalic();
    font-size: $h2FontSize;
    line-height: #{$h2FontSize * 1.175};
    font-weight: 500;

    @include media-breakpoint-up(md) {
      font-size: $h1FontSize;
      line-height: #{$h1FontSize * 1.175};
    }
  }

  h2,
  .h2-style {
    // @include poppinsBold();
    font-weight: 500;
    font-size: $fontSize28;
    line-height: #{$fontSize28 * 1.175};

    @include media-breakpoint-up(md) {
      font-size: $h2FontSize;
      line-height: #{$h2FontSize * 1.175};
    }
  }

  h3,
  .h3-style {
    font-weight: 500;
    font-size: $fontSize24;
    line-height: #{$fontSize24 * 1.175};

    @include media-breakpoint-up(md) {
      font-size: $fontSize28;
      line-height: #{$fontSize28 * 1.175};
    }
  }

  h4,
  .h4-style {
    font-weight: 500;
    font-size: $fontSize22;
    line-height: #{$fontSize22 * 1.175};

    @include media-breakpoint-up(md) {
      font-size: $fontSize24;
      line-height: #{$fontSize24 * 1.175};
    }
  }

  b,
  h5,
  h6,
  .h5-style {
    font-weight: 500;
    font-size: $bodyFontSize;
    line-height: $bodyLineHeight;
  }

  h6 {
    font-weight: 700;
  }
}
