.wysiwyg {
  // typography -----------------------------------------------------------------------------------------
  h2, h3, h4, h5, h6 {
    margin-bottom: 30px;
  }

  h2, h3 {
    font-weight: 900;
    color: $clrPrimary;
  }

  h1 {
    color: $clrPrimary;
    margin-bottom: 40px;
    font-family: $headerFontFamily;
    font-weight: 900;
    line-height: 1;
  }

  p + h2 {
    margin-top: 70px;
  }

  p + h3,
  p + h4,
  p + h5,
  p + h6 {
    margin-top: 30px;
  }

  p {
    // @include poppinsMedium();
    margin-bottom: 15px;
  }

  a {
    text-decoration: underline;
    color: $clrPrimary;
    transition: color 0.2s linear;

    &:hover {
      color: darken($clrPrimary, 30%);
    }
  }

  // lists ----------------------------------------------------------------------------------------------
  ul {
    padding: 0;
    list-style: none;

    li {
      padding: 5px 0 5px 20px;
      margin-bottom: 5px;
      position: relative;
      display: block;

      &::before {
        content: "\2022";
        display: block;
        position: absolute;
        left: 0;
      }
    }
  }

  ol {
    padding: 0;
    list-style: none;
    counter-reset: a;

    li {
      padding: 5px 0 5px 20px;
      margin-bottom: 5px;
      position: relative;
      display: block;
      counter-increment: a;

      &::before {
        content: counter(a);
        display: block;
        position: absolute;
        left: 0;
      }
    }
  }

  // various --------------------------------------------------------------------------------------------
  blockquote {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      font-size: 60px;
      content: "\201C";
      color: #e0e0e0;
      transform: translate(-15px, 40px);
      display: inline-block;
    }

    &::after {
      font-size: 60px;
      content: "\201D";
      color: #e0e0e0;
      transform: translate(15px, -8px);
      display: inline-block;
    }
  }

  table {
    width: 100%;
    border: 1px solid $clrGreyDark;

    td {
      border: 1px solid $clrGreyDark;
      padding: 20px 25px;
    }
  }
}

.section--text-image {
  .text-image__wrapper {
    display: flex;
    justify-content: space-between;

    &.image--right {
      .text-image__img {
        width: calc(50% - 15px);

        img {
          height: 100%;
          object-fit: cover;
        }
      }

      .text-image__text {
        width: calc(50% - 15px);
      }
    }

    &.image--left {
      .text-image__img {
        width: calc(50% - 15px);

        img {
          height: 100%;
          object-fit: cover;
        }
      }

      .text-image__text {
        width: calc(50% - 15px);
      }
    }
  }
}
