// hover effects ----------------------------------------------------------
@mixin hoverUnderline($clr) {
  position: relative;
  display: inline-block;
  text-decoration: none;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 2px;
    left: 0;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform $durationNormal $easeOutQuad;
    background-color: $clr;
  }

  &:hover{
    text-decoration: none;

    &::after {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }
}
