.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  z-index: 50;

  &__link {
    color: $clrPrimary;
  }

  &__wrapper {
    font-size: $fontSize12;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      width: 100%;
      justify-content: space-between;
    }

    p {
      margin-bottom: 0;
    }

    .footer__separator {
      display: block;
      margin: 0 15px;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}
