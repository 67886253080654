// variables
$generalBackgroundColor: #f0f0f0;
$textcolor: #444;
$buttoncolor: #000;
$buttonBackgroundColor: (normal: #444, hover: lighten(#444, 20%));
$buttonFadedBackgroundColor: (normal: rgba(#fff, 0.5), hover: rgba(#fff, 0.7));
$cookieGreyMedium: #ddd;

// main container -----------------------------------------------------------------------------------------
.cookie__banner {
  z-index: 1054;
  animation: fadeEffect 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  display: none;

  &.js-visible {
    display: block;
  }

  .hidden {
    display: none;
  }

  &--fullscreen {
    padding-top: 150px;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(black, 0.5);
    overflow-y: auto;

    .cookie__banner__inner {
      width: calc(100% - 40px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      bottom: auto;
      margin-bottom: 10%;
      max-height: calc(100vh - 40px);
    }
  }
}

.cookie__banner__inner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  padding: 30px 30px 30px 20px;
  background-color: $generalBackgroundColor;
  color: $textcolor;
  font-size: 0.75rem;
  line-height: 20px;
  display: block;
  overflow-y: auto;
  -ms-overflow-style: none;
  max-height: 100vh;
  box-shadow: 2px 3px 21px -6px rgba(0, 0, 0, 0.75);

  @media screen and (min-width: 767px) {
    bottom: 20px;
    left: 20px;
    max-width: 500px;
    min-width: 500px;
  }

  .cookie-animation_rect {
    width: 100%;
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    z-index: -1;
    background-color: $generalBackgroundColor;
  }
}

// content ------------------------------------------------------------------------------------------------
.cookie__message {
  display: none;
  animation: fadeEffect 1s cubic-bezier(0.075, 0.82, 0.165, 1);

  &.js-visible {
    display: block;
  }

  p {
    line-height: 20px;
    margin-bottom: 0.5rem;
  }
}

.cookie__policy-link {
  text-decoration: underline;
  color: inherit;
  margin-bottom: 0.5rem;
}

.cookie__details {
  &__wrapper {
    display: none;
    animation: fadeEffect 1s cubic-bezier(0.075, 0.82, 0.165, 1);

    &.js-visible {
      display: block;
    }
  }

  &.js-visible {
    display: block;
  }

  &__content {
    padding-top: 60px;
    display: none;

    &.js-visible {
      display: block;
    }
  }

  &__back-btn {
    display: flex;
    background: white;
    border: none;
    align-items: center;
    padding: 5px 10px;
    transition: all 0.4s ease-out;
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
      background-color: $cookieGreyMedium;
    }

    svg {
      stroke: black;
      fill: none;
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  .host {
    margin: 10px 0;

    &.js-visible {
      .host__title {
        svg {
          transform: rotate(180deg);
        }
      }

      .host__cookie__wrapper {
        display: block;
      }
    }

    &__title {
      padding: 10px;
      background-color: $cookieGreyMedium;
      color: black;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        stroke: black;
        fill: none;
        display: block;
        width: 10px;
        height: 6px;
        transition: all 0.4s ease-out;
      }
    }

    &__cookie {
      background: white;
      padding: 10px;
      margin: 10px 0;

      &__wrapper {
        display: none;
        animation: fadeEffect 1s cubic-bezier(0.075, 0.82, 0.165, 1);

        &.js-visible {
          display: block;
        }
      }

      &__field {
        display: flex;
      }

      &__label {
        font-weight: bold;
        min-width: 100px;
      }
    }
  }
}

// checkboxes --------------------------------------------------------------------------------------------
.cookie__options {
  // padding-top: 10px; // ruimte voor .cookie-animation_rect
  margin-top: -10px; //    "
  position: relative;
  display: none;
  animation: fadeEffect 1s cubic-bezier(0.075, 0.82, 0.165, 1);

  &.js-visible {
    display: block;
  }

  &__checkbox-wrapper {
    display: none;
    animation: fadeEffect 1s cubic-bezier(0.075, 0.82, 0.165, 1);

    &.js-visible {
      display: block;
    }
  }

  .cookie__options__title {
    width: calc(100% - 80px);
    padding-bottom: 20px;
    display: block;
  }

  .cookie__button-close {
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;
    position: absolute;
    right: -15px;
    top: -7px;

    i {
      width: 30px;
      height: 1px;
      display: block;
      position: absolute;
      top: 50%;
      left: calc(50% - 15px);
      transform-origin: center;
      transform: rotate(45deg);
      background: $buttoncolor;

      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }

  form {
    margin-bottom: 40px;
    margin-top: 25px;
  }
}

// custom checkbox
.cookie__checkbox {
  display: flex;
  margin-bottom: 15px;
  position: relative;
  align-items: center;

  &.disabled {
    opacity: 0.5;
  }

  &__info {
    &__title {
      font-weight: bold;
      display: flex;
      align-items: center;
      cursor: pointer;

      &::after {
        content: '+';
        display: block;
        font-size: 18px;
        margin-left: 0.5rem;
      }
    }

    &__btn-details {
      padding: 0;
      border: none;
      background-color: transparent;
      text-decoration: underline;
      margin-top: 10px;
      font-size: 0.75rem;
      color: black;
    }

    &__descr {
      display: none;
      animation: fadeEffectSmall 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &.js-visible {
      .cookie__checkbox__info__title {
        &::after {
          content: '-';
        }
      }

      .cookie__checkbox__info__descr {
        display: block;
      }
    }
  }

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
    flex-shrink: 0;
    margin-right: 1rem;
    margin-bottom: 0;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    input:checked + .slider {
      background-color: $clrPrimary;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px $clrPrimary;
    }

    input:checked + .slider::before {
      transform: translateX(13px);
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: 0.4s;
    }

    .slider::before {
      position: absolute;
      content: "";
      height: 13px;
      width: 13px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      transition: 0.4s;
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round::before {
      border-radius: 50%;
    }
  }
}

// buttons -----------------------------------------------------------------------------------------------
.cookie__button-wrapper {
  margin-top: 20px;

  &.hidden {
    display: none;
  }

  .button {
    margin-right: 15px;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: bold;
    display: inline-block;
    transition: all $durationNormal $easeOutQuad;

    &:hover {
      text-decoration: none;
    }
  }

  .button--primary {
    padding: 10px 20px;
    background: $clrPrimary;
    color: white;

    &:hover {
      background: darken($clrPrimary, 10%);
      transform: scale(1.1);
    }
  }
}

.cookie__button,
.cookie__button--faded {
  padding: 10px 25px;
  margin: 0 10px 10px 0 !important;
  background-color: map-get($buttonBackgroundColor, normal);
  transition: background 0.4s linear;

  &:hover {
    color: $buttoncolor;
    text-decoration: none;
    background-color: map-get($buttonBackgroundColor, hover);
  }
}

.app-body {
  .cookie__button {
    color: white;
  }

  .cookie__button--faded {
    color: #444;
  }
}

.cookie__button--faded {
  background-color: white;

  &:hover {
    background-color: #ddd;
  }
}

@keyframes fadeEffect {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeEffectSmall {
  from {
    opacity: 0;
    transform: translateY(-15px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
