.header {
  position: fixed;
  width: 100%;
  z-index: 100;

  &__wrapper {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(lg) {
      max-height: 70px;
    }
  }
}

.header__bg {
  display: none;

  @include media-breakpoint-down(lg) {
    display: block;
    position: absolute;
    background: $clrBg;
    width: 100%;
    top: 0;
    left: 0;
    height: 90px;
    z-index: 4;
    border-bottom: 1px solid $clrPrimary;
  }

  @include media-breakpoint-down(lg) {
    height: 70px;
  }
}

.header__logo {
  z-index: 5;

  svg {
    width: 209px;

    @include media-breakpoint-down(lg) {
      width: 150px;
    }
  }
}

.header__hamburger {
  display: none;
  padding: 0;

  @include media-breakpoint-down(lg) {
    display: block;
    z-index: 5;
  }

  .hamburger {
    padding: 0;
    align-items: center;
    justify-content: center;
  }
}

.header__nav {
  display: flex;
  align-items: center;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    position: absolute;
    padding-top: 150px;
    width: 100%;
    left: 0;
    top: 0;
    height: 100vh;
    background-color: $clrBg;
    transform: translateY(-100%);
    transition: transform $durationNormal $easeOutQuad;

    &.js-open {
      transform: translateY(0);
    }
  }

  &__popup-menu {
    &__list {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }
    }

    &__item {
      &__link {
        padding: 10px 25px;
        color: $clrPrimary;

        @include media-breakpoint-down(lg) {
          padding: 20px 0;
          font-size: $fontSize22;
          display: block;
        }

        @include media-breakpoint-down(sm) {
          font-size: $fontSize18;
        }

        &:hover {
          text-decoration: none;
        }

        span {
          cursor: pointer;
          @include hoverUnderline($clrPrimary);
        }
      }
    }
  }

  &__seperator {
    display: block;
    margin: 0 45px 0 20px;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__share {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }

    &__text {
      font-weight: $fntWeightBold;

      @include media-breakpoint-down(lg) {
        font-size: $fontSize22;
        padding: 20px 0 10px 0;
      }

      @include media-breakpoint-down(sm) {
        font-size: $fontSize18;
      }
    }

    &__link {
      width: 20px;
      height: 20px;
      display: flex;
      margin-left: 20px;

      @include media-breakpoint-down(lg) {
        margin: 0 10px;
      }

      &__wrapper {
        display: flex;
        align-items: center;
      }

      &:hover {
        svg {
          transform: scale(1.5);
        }
      }

      svg {
        transition: transform $durationNormal $easeOutQuad;
        transform-origin: center center;
        fill: $clrPrimary;
      }
    }
  }
}
