.modal {
  @include media-breakpoint-down(sm) {
    background: $clrBgLight;
  }

  &-dialog {
    max-width: 750px;
  }

  &-header {
    justify-content: center;
  }

  &-content {
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    text-align: center;

    &__bg {
      opacity: 1;
      max-height: 94vh;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 100%;

        @include media-breakpoint-down(sm) {
          visibility: hidden;
        }
      }
    }

    &__wrapper {
      position: absolute;
      overflow-y: scroll;
      width: 100%;
      max-height: 600px;

      @include media-breakpoint-down(sm) {
        height: 100vh;
        max-height: none;
        padding-top: 50px;
      }

      .modal-body {
        height: 100%;
      }
    }
  }

  .btn-close {
    position: absolute;
    background-color: $clrTertiary;
    top: 27px;
    right: 120px;
    opacity: 1;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid $clrPrimary;
    z-index: 1;
    transition: transform $durationNormal $easeOutQuad;

    @include media-breakpoint-down(lg) {
      width: 35px;
      height: 35px;
      top: 10px;
      right: 10px;
    }

    &:hover {
      background-color: $clrSecondary;
      border: 1px solid $clrPrimary;
      transform: rotate(180deg);
    }
  }
}

.modal__book {
  transition: transform $durationNormal $easeOutQuad;

  @include media-breakpoint-down(sm) {
    max-width: 250px;
    margin: 0 auto 24px auto;
  }

  &:hover {
    transform: scale(1.05);
  }

  &__audio-file {
    width: 100%;
  }

  &__img {
    width: 100%;

    img {
      width: 100%;
      transition: transform $durationNormal $easeOutQuad;
    }
  }
}

.plyr {
  &__volume {
    min-width: auto;
  }
}
