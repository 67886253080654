.story__plains {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  // z-index: 20;

  &__plain {
    width: 100%;
    position: absolute;
    bottom: 0;

    @include media-breakpoint-down(lg) {
      min-width: 1500px;
    }

    @include media-breakpoint-down(sm) {
      bottom: -60px;
      min-width: 1100px;
    }

    &--2 {
      display: none;
    }
  }
}

// Story layers
.story__layer-back {
  z-index: 10;
}

.story__layer-front {
  z-index: 30;
}

.story {
  background-color: $clrBg;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  &--bg-none {
    background: transparent;
  }

  &__section {
    width: 100%;
    height: 100vh;

    h1, h2, h3, h4 {
      font-family: $headerFontFamily;
      font-size: $fontSize56;
      line-height: 1;

      @include media-breakpoint-down(xxl) {
        font-size: $fontSize46;
      }

      @include media-breakpoint-down(sm) {
        font-size: $fontSize32;
      }
    }
  }
}

.story__section__black-sun {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 100%;

  &__text {
    text-align: center;
    padding: 0 24px;
  }
}

.story__section__screen1 {
  &__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 15%;
    align-items: center;
    transform-origin: top right;
  }
}

.story__section__screen2 {
  opacity: 0;

  &__txt {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    padding: 0 24px;
  }

  &__img {
    position: absolute;
    left: 50%;
    top: 120%;
    transform: translateX(-50%) translateY(-50%);

    @include media-breakpoint-down(lg) {
      transform: translateX(-50%) translateY(70%);
    }

    @include media-breakpoint-down(sm) {
      transform: translateX(-50%) translateY(50%);
    }

    &__wrapper {
      position: absolute;

      @include media-breakpoint-up(lg) {
        position: relative;
      }
    }

    img {
      max-width: 250px;

      @include media-breakpoint-down(xxl) {
        max-width: 200px;
      }

      @include media-breakpoint-down(sm) {
        max-width: 140px;
      }
    }
  }
}

.story__section__screen3 {
  opacity: 0;

  &__txt {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    padding: 0 24px;

    @include media-breakpoint-down(sm) {
      top: 45%;
    }
  }

  &__img {
    z-index: 5;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: scale(0);

    &__mayan-temple {
      bottom: 47px;
      left: 1%;
      max-width: 300px;

      @include media-breakpoint-down(xxl) {
        max-width: 250px;
      }

      @include media-breakpoint-down(lg) {
        bottom: 100px;
        max-width: 200px;
      }

      @include media-breakpoint-down(sm) {
        max-width: 120px;
        bottom: 35px;
      }
    }

    &__pyramids {
      bottom: 97px;
      left: 14%;
      max-width: 500px;
      z-index: 4;

      @include media-breakpoint-down(xxl) {
        max-width: 400px;
      }

      @include media-breakpoint-down(lg) {
        bottom: 150px;
        max-width: 350px;
      }

      @include media-breakpoint-down(sm) {
        max-width: 180px;
        bottom: 80px;
      }
    }

    &__colloseum {
      max-width: 350px;
      bottom: 170px;
      left: 41%;

      @include media-breakpoint-down(xxl) {
        max-width: 300px;
        bottom: 120px;
      }

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    &__lady-liberty {
      z-index: 3;
      bottom: 220px;
      max-width: 180px;
      left: 57%;

      @include media-breakpoint-down(xxl) {
        bottom: 200px;
        max-width: 150px;
      }

      @include media-breakpoint-down(lg) {
        bottom: 180px;
        max-width: 120px;
      }

      @include media-breakpoint-down(sm) {
        max-width: 70px;
        bottom: 100px;
      }
    }

    &__chinese-temple {
      bottom: 50px;
      left: auto;
      max-width: 530px;
      right: 3%;

      @include media-breakpoint-down(xxl) {
        max-width: 450px;
      }

      @include media-breakpoint-down(lg) {
        bottom: 100px;
        max-width: 300px;
      }

      @include media-breakpoint-down(sm) {
        bottom: 70px;
        max-width: 150px;
      }
    }
  }
}

.story__section__screen4 {
  opacity: 0;

  &__bg {
    background: $clrWhite;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &__god {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;

    &__hand {
      max-width: 230px;
      opacity: 0;
      transform: translateY(-100px);

      @include media-breakpoint-down(sm) {
        max-width: 170px;
      }
    }

    &__lightning {
      position: absolute;
      width: 1170px;
      left: -531px;
      top: 281px;
      opacity: 0;

      @include media-breakpoint-down(sm) {
        width: 870px;
        left: -395px;
        top: 208px;
      }
    }
  }
}

.story__section__screen5 {
  opacity: 0;

  &__txt {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    padding: 0 24px;

    @include media-breakpoint-down(lg) {
      top: 45%;
    }
  }

  &__btn {
    opacity: 0;
    transform: translateY(50px);
  }
}

.img-black-sun {
  max-width: 350px;
  margin-bottom: 40px;

  @include media-breakpoint-down(xxl) {
    max-width: 300px;
    margin-bottom: 30px
  }

  @include media-breakpoint-down(sm) {
    max-width: 200px;
  }
}
