$clrVideoPlayBtn: $white;

button {
  background: none;
  border: none;
}

.button {
  display: inline-block;
  padding: 0;
  position: relative;
  transition: transform $durationNormal $easeOutQuad;

  &__inner {
    padding: 10px 35px;
    background: $clrPrimary;
    color: $clrWhite;
    font-size: $fontSize20;
    font-weight: bold;
    display: block;

    @include media-breakpoint-down(sm) {
      font-size: $fontSize16;
    }
  }

  &:hover {
    text-decoration: none;
    transform: scale(1.1);
  }
}

.button,
.wysiwyg .button {

}
