.pt-small {
  padding-top: map-get($componentSpacingsMobile, small);

  @include media-breakpoint-up(lg) {
    padding-top: map-get($componentSpacings, small);
  }
}

.pb-small {
  padding-bottom: map-get($componentSpacingsMobile, small);

  @include media-breakpoint-up(lg) {
    padding-bottom: map-get($componentSpacings, small);
  }
}

.pt-medium {
  padding-top: map-get($componentSpacingsMobile, medium);

  @include media-breakpoint-up(lg) {
    padding-top: map-get($componentSpacings, medium);
  }
}

.pb-medium {
  padding-bottom: map-get($componentSpacingsMobile, medium);

  @include media-breakpoint-up(lg) {
    padding-bottom: map-get($componentSpacings, medium);
  }
}

.pt-large {
  padding-top: map-get($componentSpacingsMobile, large);

  @include media-breakpoint-up(lg) {
    padding-top: map-get($componentSpacings, large);
  }
}

.pb-large {
  padding-bottom: map-get($componentSpacingsMobile, large);

  @include media-breakpoint-up(lg) {
    padding-bottom: map-get($componentSpacings, large);
  }
}

.pt-xlarge {
  padding-top: map-get($componentSpacingsMobile, xlarge);

  @include media-breakpoint-up(lg) {
    padding-top: map-get($componentSpacings, xlarge);
  }
}

.pb-xlarge {
  padding-bottom: map-get($componentSpacingsMobile, xlarge);

  @include media-breakpoint-up(lg) {
    padding-bottom: map-get($componentSpacings, xlarge);
  }
}

// Margins
.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-small {
  margin-top: map-get($componentSpacingsMobile, small);

  @include media-breakpoint-up(lg) {
    margin-top: map-get($componentSpacings, small);
  }
}

.mb-small {
  margin-bottom: map-get($componentSpacingsMobile, small);

  @include media-breakpoint-up(lg) {
    margin-bottom: map-get($componentSpacings, small);
  }
}

.mt-medium {
  margin-top: map-get($componentSpacingsMobile, medium);

  @include media-breakpoint-up(lg) {
    margin-top: map-get($componentSpacings, medium);
  }
}

.mb-medium {
  margin-bottom: map-get($componentSpacingsMobile, medium);

  @include media-breakpoint-up(lg) {
    margin-bottom: map-get($componentSpacings, medium);
  }
}

.mt-large {
  margin-top: map-get($componentSpacingsMobile, large);

  @include media-breakpoint-up(lg) {
    margin-top: map-get($componentSpacings, large);
  }
}

.mb-large {
  margin-bottom: map-get($componentSpacingsMobile, large);

  @include media-breakpoint-up(lg) {
    margin-bottom: map-get($componentSpacings, large);
  }
}

.mt-xlarge {
  margin-top: map-get($componentSpacingsMobile, xlarge);

  @include media-breakpoint-up(lg) {
    margin-top: map-get($componentSpacings, xlarge);
  }
}

.mb-xlarge {
  margin-bottom: map-get($componentSpacingsMobile, xlarge);

  @include media-breakpoint-up(lg) {
    margin-bottom: map-get($componentSpacings, xlarge);
  }
}

.height-100 {
  height: 100vh;
}
